<template>
	<div class="form-admin">
		<div class="row-space-tbf">
			<div class="space-left"></div>
			<div class="content">
				<div class="main-content">
					<div class="form-section">
						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">Continut*</label>
								<div v-if="$v.description.$error" class="error-msg">{{ $t('validator.required') }}</div>
							</div>
							<div class="input-box" v-bind:class="{has_error: $v.description.$error}">
								<editor
								api-key="nna4boz68ni73aby5h1rj5z29qgi7kpku4fe2v16rsdcdeou"
								class="editor-tbf"
								v-model="description"
								:init="{
									height: 800,
									automatic_uploads: true,
									images_upload_handler: filePickerCallback,
									convert_urls: false,
									menubar: false,
									plugins: [
									'advlist autolink lists link textcolor colorpicker image imagetools charmap print preview anchor',
									'searchreplace visualblocks code fullscreen',
									'insertdatetime media table paste code help wordcount'
									],
									toolbar:
									'undo redo | formatselect | forecolor backcolor | bold italic | \
									alignleft aligncenter alignright alignjustify | \
									bullist numlist outdent indent | removeformat | image'
								}"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="second-content">
					<div class="form-section">
						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">Nume*</label>
								<div v-if="$v.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
							</div>
							<div class="input-box" v-bind:class="{has_error: $v.name.$error}">
								<div class="icon-left"><icon-updates /></div>
								<div class="icon-right" v-if="name" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
								<input type="text" placeholder="Scrie aici numele actualizare" class="input-text" v-model="name">
							</div>
						</div>

						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">Tag*</label>
								<div v-if="$v.tag.$error" class="error-msg">{{ $t('validator.required') }}</div>
							</div>
							<div class="input-select-options">
								<div class="option-item" :class="{ active: tag == 'admin'}" @click="tag = 'admin'">Doar admini</div>
								<div class="option-item" :class="{ active: tag == 'employee'}" @click="tag = 'employee'">Pentru toti</div>
							</div>
						</div>

						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">Conditie*</label>
								<div v-if="$v.type_selected.$error" class="error-msg">{{ $t('validator.required') }}</div>
							</div>
							<div class="input-select-options">
								<div class="option-item" v-for="type in group_types" :class="{ active: type_selected == type }" @click="type_selected = type">{{ $t(`type_updates.${type}`) }}</div>
							</div>
						</div>

						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">Softuri*</label>
								<div v-if="$v.application_ids.$error" class="error-msg">{{ $t('validator.required') }}</div>
							</div>
							<div class="input-select-options">
								<div class="option-item" v-for="app in applications" :class="{ active: application_ids.includes(app.id), disabled: applications_disabled.includes(app.id) }" @click="applications_disabled.includes(app.id) ? '' : selectOptionArray('application_ids', app.id)">{{ app.name }}</div>
							</div>
						</div>
					</div>

					<div class="form-submit">
						<button class="btn-tbf grey center" @click="$router.push({name: 'admin-updates'})">
							<div class="loader"></div>
							<div class="text">{{ $t('general.cancel') }}</div>
						</button>
						<button id="buttonSave" class="btn-tbf blue center" @click="saveForm">
							<div class="loader"></div>
							<div class="text">{{ type == 'create' ? $t('general.add') : $t('general.update') }}</div>
						</button>
					</div>
				</div>
			</div>
			<div class="space-right"></div>
		</div>
	</div>
</template>

<script>
	import IconClose from '../../Icons/Close'
    import IconUpdates from '../../Icons/UpdatesNotifications'
	import { required } from 'vuelidate/lib/validators'
	import Editor from '@tinymce/tinymce-vue'

    export default {
    	components: {
            IconUpdates,
            IconClose,
            Editor
        },
        validations: {
			name: {required},
			description: {required},
			tag: {required},
			application_ids: {required},
			type_selected: {required}
		},
        data() {
            return {
				loaded: true,
				name: '',
				description: '',
				tag: '',
				application_ids: [],
				applications_disabled: [],
				applications: [],
				group_types: [ 'All' , 'Single', 'Bundle'],
				type_selected: 'All',
				type: 'create',
				type_item: "updates"
            }
        },
        mounted(){
			this.$root.$emit("navbar_title", 'Adaugare actualizare sistem');
			this.getApplications()

			if(this.$route.params.id){
				this.type = 'edit';
				this.getItemData();
			}
        },
        methods: {
        	getApplications() {
        		axios.get('/applications')
        		.then(({data}) => {
        			this.applications = data.data
        		})
        	},
        	getItemData() { 
        		axios.get(`/software-updates/${this.$route.params.id}/edit`)
        		.then(({data}) => {
        			this.name = data.data.name
        			this.description = data.data.description
        			this.tag = data.data.tag
        			this.application_ids = data.data.application_ids
        			this.applications_disabled = [...data.data.application_ids]
        			this.type_selected = data.data.group_type
        		})
        	},
        	selectOptionArray(key, value) {
        		if(this[key].includes(value)){
        			this[key].splice(this[key].indexOf(value), 1)
        		} else {
        			this[key].push(value)
        		}
        	},
        	filePickerCallback(blobInfo, success, failure, progress){
        		var formData = new FormData();
        		formData.append('file', blobInfo.blob(), blobInfo.filename());

        		axios.post('software-updates/upload-image', formData)
        		.then(({data}) => {
        			success(data.file_path);
        		}).catch((err) => {
        			failure(err.response.data.message);
        		})
        	},
        	saveForm() { 
        		var buttonName = `buttonSave`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.error_token = ''
				this.$v.$touch()

				if(!this.$v.$invalid){
					let formData = {
						name: this.name,
						description: this.description,
						application_ids: this.application_ids,
						tag: this.tag,
						type: this.type_item,
						group_type: this.type_selected
					}

					if(this.type == 'create') {
						this.store(formData, btnSubmit, btnSubmitText, btnSubmitLoader)
					} else {
						this.update(formData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}
				} else {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
        	},
        	store(formData, btnSubmit, btnSubmitText, btnSubmitLoader) {
        		axios.post('/software-updates/store', formData)
				.then(() => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							this.$router.push({name: 'admin-updates'})
						}, 500)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
        	},
        	update(formData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/software-updates/${this.$route.params.id}`, formData)
				.then(() => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							this.$router.push({name: 'admin-updates'})
						}, 500)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
			}
        }
    }
</script>

<style lang="scss">
</style>